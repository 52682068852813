<template>
  <div class="common-form">
    <h3 v-if="title" class="common-form__title">{{ title }}</h3>
    <div v-if="items.length" class="common-form__body">
      <CommonFormRow
        ref="rows"
        v-for="item of items"
        :key="item.id"
        :id="item.id"
        :item="item"
        :isEdited="isEdited"
        @searchValue="searchValue"
        @setValue="setValue"
        @updated="updated"
      />
    </div>
    <div v-if="items.length" class="common-form__actions">
      <Button theme="common" :disabled="!isValid" @click="editToggle">
        {{ buttonTitle }}
      </Button>
    </div>
  </div>
</template>

<script>
import CommonFormRow from "@/components/CommonFormRow.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    CommonFormRow,
    Button,
  },
  name: "CommonForm",
  props: {
    title: { type: String },
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      isEdited: false,
      isValid: true,
    };
  },
  computed: {
    buttonTitle() {
      return this.isEdited ? "Сохранить" : "Редактировать профиль";
    },
  },
  watch: {
    isEdited(value) {
      if (!value) {
        this.$emit("save");
      }
    },
  },
  methods: {
    editToggle() {
      this.isEdited = !this.isEdited;
    },
    searchValue(value) {
      this.$emit("searchValue", value);
    },
    setValue(value) {
      this.$emit("setValue", value);
    },
    updated(value) {
      this.$emit("updated", value);
      if ("isValid" in value) {
        this.isValid = value.isValid;
      }
    },
    getRow(rowName) {
      return this.$refs.rows.find((item) => item.id === rowName);
    },
    getRowValue(field) {
      return this.getRow(field.id).getInputValue(field.keys);
    },
    getValues(fields) {
      const result = {};
      fields.forEach((field) => {
        result[field.id] = this.getRowValue(field);
      });
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.common-form {
  width: 700px;
}

.common-form__body {
  display: grid;
  gap: 10px;
  margin-bottom: 60px;
}

.common-form__actions {
  display: flex;
  gap: 10px;
  margin-top: 26px;
}
</style>
