<template>
  <div class="common-form__row">
    <label class="common-form__row-label">{{ item.label }}</label>
    <template v-if="isEdited && item.isEditable">
      <Select
        v-if="item.type === 'select'"
        ref="input"
        :id="item.id"
        :options="item.list"
        :default="localValue"
        :showModel="item.showModel"
        :mode="['table', 'with-search', 'rounded']"
        searchPlaceholder="Временная зона"
        @selectOption="setOption"
        @searchValue="searchValue"
        @updated="updated"
      />
      <CommonInput
        v-else
        ref="input"
        v-model="localValue"
        :id="item.id"
        :type="item.type"
        :value="localValue"
        :placeholder="item.placeholder"
        :required="item.required"
        :disabled="item.disabled"
        :validator="item.validator"
        :formatter="item.formatter"
        :classes="item.classes"
        :listeners="item.listeners"
        @updated="updated"
      />
    </template>
    <div v-else class="common-form__value">{{ localValue }}</div>
  </div>
</template>

<script>
import Select from "@/components/Select";
import CommonInput from "@/components/CommonInput.vue";

export default {
  name: "CommonFormRow",
  components: { Select, CommonInput },
  props: {
    id: { type: [String, Number] },
    item: { type: Object },
    isEdited: { type: Boolean },
  },
  data() {
    return {
      localValue: null,
      matchedValue: "",
    };
  },
  watch: {
    item: {
      handler(data) {
        let value = null;

        if (data.valueMatchKey) {
          const foundEl = data.list.find(
            (item) => item[data.valueMatchKey] === data.value
          );
          value = foundEl ? data.showModel(foundEl) : data.value;
        } else {
          value = data.value;
        }
        if (this.item.formatter) {
          value = this.item.formatter(value);
        }
        this.localValue = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setOption(option) {
      const { showModel } = this.item;
      this.localValue = showModel(option);
      this.$emit("setValue", { name: this.item.id, value: option });
    },
    searchValue(value) {
      this.$emit("searchValue", value);
    },
    updated(data) {
      this.$emit("updated", data);
      if (data.changed) {
        const { value } = data.changed;
        const { showModel } = this.item;
        this.localValue = showModel ? showModel(value) : value;
        if (data.changed.elemType === "select") {
          this.matchedValue = value[this.item.valueMatchKey];
        }
      }
    },
    // если нам нужно получить значения для списка (может быть при multiple у select),
    // то можно указать массив ключей, из которых будет создано значение в списке
    // при указании одного ключа вернется массив с примитивами, равными значению ключа
    // указав больше одного значения вернется список, содержащий объекты ключей и их значений
    // не указав ключей вернется неизмененный объект
    getInputValue(keys = []) {
      const getItem = (item) => {
        if (!item) {
          return;
        }
        let result = {};
        if (!keys.length) {
          result = item;
          return;
        }
        keys.forEach((key) => {
          if (item[key] !== undefined || item[key] !== null) {
            result = item[key];
          }
        });
        return result;
      };

      if (this.item.type === "select" && this.item.list?.length) {
        const option = this.item.list.find(
          (el) => el[this.item.valueMatchKey] === this.matchedValue
        );
        return getItem(option);
      }
      return this.localValue;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.common-form__row {
  display: grid;
  grid-template-columns: 300px 1fr;
  column-gap: 16px;
}

.common-form__row-label {
  display: flex;
  align-items: center;
}
</style>
