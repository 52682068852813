<template>
  <table v-if="data.length" class="table">
    <thead>
      <tr>
        <th class="table__row table__row--heading table__row--description">
          Кол-во дней
        </th>
        <th
          v-for="number in data.length"
          :key="'description-' + number"
          class="table__row table__row--heading"
        >
          <div class="table__row-date">
            <div>{{ getHeading(number - 1).year }}</div>
            <div>{{ getHeading(number - 1).month }}</div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="rowId in rowsIds"
        :key="'col-' + rowId"
        :class="{ 'table__row--delimiter': rowId === 'delimiter' }"
      >
        <td
          v-if="rowId === 'delimiter'"
          :colspan="data.length + 1"
          class="table__row--description"
        >
          {{ description[rowId] }}
        </td>
        <td v-else>{{ description[rowId] }}</td>
        <template v-if="rowId !== 'delimiter'">
          <td
            v-for="(month, index) in data"
            :key="rowId + month.pc_month.id"
            class="table__row table__row--body"
          >
            <template
              v-if="
                (rowId === 'available_hours' ||
                  rowId === 'available_hours_per_day') &&
                index > currentMonthIndex
              "
            >
              <Input
                v-if="rowId === 'available_hours_per_day'"
                :startValue="month[rowId]"
                :isOnlyNumbers="true"
                :save-data="putAvailability"
                :maxNumber="24"
                :context="{ id: month.id, key: 'available_hours_per_day' }"
                class="table__input"
              />
              <Input
                v-else
                :startValue="month[rowId]"
                :isOnlyNumbers="true"
                :save-data="putAvailability"
                :maxNumber="744"
                :context="{ id: month.id, key: 'available_hours' }"
                class="table__input"
              />
            </template>
            <template v-else>{{ getCol(rowId, index) }}</template>
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { MONTHS } from "@/assets/constants.js";
import { isNumber } from "@/assets/utils";
import Input from "@/components/Input.vue";

const rowsIds = [
  "days",
  "working_days",
  "holidays",
  "vacation_days",
  "sick_days",
  "delimiter",
  "available_hours_per_day",
  "available_hours",
];

const description = {
  id: "description",
  days: "календарных",
  working_days: "рабочих",
  holidays: "вых./праздн.",
  vacation_days: "отпускных",
  sick_days: "больничных",
  delimiter: "Кол-во часов доступности",
  available_hours_per_day: "в день",
  available_hours: "в месяц",
};

export default {
  name: "UserAvailability",
  components: { Input },
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      rowsIds,
      description,
    };
  },
  computed: {
    ...mapGetters({
      getMyDate: "userStore/getMyDate",
    }),
    currentMonthIndex() {
      if (!this.getMyDate) {
        return null;
      }
      const currentMonthNumber = this.getMyDate.month() + 1;
      const currentYear = this.getMyDate.year();

      return this.data.findIndex(
        (dateInfo) =>
          dateInfo.pc_month.name === currentMonthNumber &&
          dateInfo.pc_month.year.number === currentYear
      );
    },
  },
  methods: {
    ...mapActions({
      changeStaffActivity: "planningsStore/changeStaffActivity",
      changeStaffDayActivity: "planningsStore/changeStaffDayActivity",
    }),
    getCol(id, index) {
      return this.data[index][id];
    },
    getHeading(index) {
      const date = this.data[index].pc_month;
      return {
        month: MONTHS[date.name - 1],
        year: date.year.number,
      };
    },
    async putAvailability(hours, { key, id }) {
      let response = false;
      if (!isNumber(hours)) {
        hours = 0;
      }

      if (key === "available_hours") {
        response = await this.changeStaffActivity({
          available_hours: hours,
          id,
        });
      } else {
        response = await this.changeStaffDayActivity({
          available_hours: hours,
          id,
        });
      }

      if (response.success) {
        this.$emit("update-hours");
      }

      return response;
    },
  },
};
</script>

<style lang="scss" scope>
.table__row {
  width: 120px;
  &--heading {
    vertical-align: top;
  }
  &--description {
    text-align: left;
    vertical-align: bottom;
  }
  &--body {
    text-align: center;
  }
  &--delimiter {
    height: 40px;
    font-weight: 700;
  }
}

.table__row-date {
  display: flex;
  flex-direction: column;
  height: 40px;
  margin-bottom: 20px;
}

.table__input {
  width: 60px;
  text-align: center;
}
</style>
