<template>
<div class="common-input-wrapper">
  <input
    class="common-input"
    :class="{ 'common-input--error': !isValid, ...classes }"
    type="text"
    v-model="inputValue"
    :name="id"
    :id="id"
    :placeholder="placeholder"
    :required="required"
    :disabled="disabled"
    @change="changedValue"
    @input="changedValue"
    @blur="changedValue"
    @keypress="changedValue"
  />
</div>
</template>

<script>
export default {
  name: 'SimpleInput',
  emits: ['updated'],
  props: {
    id: { type: [String, Number] },
    type: { type: String },
    value: { type: [String, Number] },
    placeholder: { type: String },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    validator: { type: Function },
    formatter: { type: Function },
    classes: { type: [String, Array, Object], require: false },
    listeners: {
      type: Array,
      required: false,
      validator: (value) => ['input', 'change', 'blur', 'keypress'].includes(value),
    },
  },
  data() {
    return {
      inputValue: this.value,
      isValid: true,
      showPassword: false,
    };
  },
  watch: {
    value(val) {
      this.inputValue = val;
    },
    inputValue(value) {
      if (!this.listeners) {
        this.$emit('updated', {
          changed: {
            elemName: this.id,
            elemType: this.type,
            value: this.inputValue,
          },
        });
      }
      if (this.required && !value) {
        this.$emit('updated', { isValid: false });
      } else if (this.required && value) {
        this.$emit('updated', { isValid: true });
      }
    },
    isValid(isTrue) {
      this.$emit('updated', { isValid: isTrue });
    },
  },
  methods: {
    changedValue(event) {
      const ACTIONS = {
        insertText: 'add',
        deleteContentBackward: 'delete',
      };
      const action = ACTIONS[event.inputType];
      if (typeof this.formatter === 'function' && event.type === 'input') {
        this.inputValue = this.formatter(this.inputValue, {
          symbol: event.data,
          action,
        });
      }
      if (typeof this.validator === 'function' && event.type === 'input') {
        this.isValid = this.validator(this.inputValue, {
          symbol: event.data,
          action,
        });
      }
      if (this.listeners && this.listeners(event.type)) {
        this.$emit('updated', {
          changed: {
            elemName: this.id,
            elemType: this.type,
            value: this.inputValue,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.common-input-wrapper {
  position: relative;
}

.common-input {
  width: 100%;
  height: 32px;
  outline: 0;
  display: block;
  border-radius: 7px;
  border: 1.5px solid ;
  background: transparent;
  font-weight: 400;
  padding: 8px 12px;

  &::placeholder {
    color: #a3b1b4;
  }

  &:disabled:hover {
    border: 1.5px solid transparent;
  }

  &--error {
    border: 1.5px solid tomato;
    &:hover {
      border: 1.5px solid tomato;
    }
  }
}
</style>
