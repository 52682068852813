<template>
  <div class="container">
    <div class="profile-block">
      <h2>Профиль</h2>
      <CommonForm
        :items="userData"
        ref="profile-form"
        @searchValue="setSearch"
        @save="saveResult"
      />
    </div>
    <div class="profile-block">
      <h2>Доступность</h2>
      <UserAvailability
        :data="employeeProductionCalendar"
        @update-hours="loadCalendar"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import CommonForm from "@/components/CommonForm.vue";
import UserAvailability from "@/components/UserAvailability.vue";
import dayjs from "dayjs";
import toObject from "dayjs/plugin/toObject";

dayjs.extend(toObject);

export default {
  name: "ProfilePage",
  components: { CommonForm, UserAvailability },
  data() {
    return {
      search: "",
    };
  },
  async created() {
    await this.loadCalendar();
  },
  computed: {
    ...mapState({
      user: (state) => state.userStore.me,
      timeZones: (state) => state.timeZones,
      serverTime: (state) => state.productionCalendarStore.serverTime,
      planningsList: (state) => state.planningsStore.planningsList,
      productionCalendar: (state) =>
        state.productionCalendarStore.productionCalendar,
      monthsName: (state) => state.months,
      staffActivities: (state) => state.planningsStore.staffActivities,
      employeeProductionCalendar: (state) =>
        state.employeeCalendarStore.employeeProductionCalendar,
      changeableMonthId: (state) =>
        state.employeeCalendarStore.changeableMonthId,
    }),
    ...mapGetters({
      getDaysOff: "productionCalendarStore/getDaysOff",
    }),
    userData() {
      return [
        {
          label: "Имя",
          id: "first_name",
          value: this.user?.first_name,
          isEditable: false,
        },
        {
          label: "Фамилия",
          id: "last_name",
          value: this.user?.last_name,
          isEditable: false,
        },
        {
          label: "Телефон",
          id: "phone",
          value: this.user?.phone,
          type: "phone",
          formatter: (value) => {
            if (!value) {
              return;
            }
            let result = value;
            if (value.length && value[0] !== "+") {
              result = `+${value}`;
            }
            return result.replace(/[^+\d]/g, "");
          },
          validator: (value) => {
            return /^\+[1-9]\d{1,14}$/.test(value);
          },
          isEditable: true,
        },
        {
          label: "telegram",
          id: "telegram_contact",
          value: this.user?.telegram_contact,
          type: "text",
          validator: (value) => {
            if (!value) {
              return;
            }
            return value?.length > 4 && value?.length < 33;
          },
          isEditable: true,
        },
        {
          label: "Временная зона",
          id: "tz",
          value: this.user?.tz,
          list: this.filteredTimeZones,
          valueMatchKey: "timezone",
          type: "select",
          isEditable: true,
          showModel: (value) => `${value.gtm_diff} ${value.timezone}`,
        },
      ];
    },
    filteredTimeZones() {
      let result = this.timeZones;
      const search = this.search;
      if (search.length < 3) {
        return result;
      }
      result = result.filter((zone) => {
        const { gtm_diff, timezone } = zone;
        return (
          gtm_diff.toLowerCase().includes(search) ||
          timezone.toLowerCase().includes(search)
        );
      });
      return result;
    },
  },
  methods: {
    ...mapActions({
      patchUser: "userStore/patchUser",
      loadPlanningsList: "planningsStore/loadPlanningsList",
      loadStaffActivities: "planningsStore/loadStaffActivities",
      loadServerTime: "productionCalendarStore/loadServerTime",
      loadProductionCalendar: "productionCalendarStore/loadProductionCalendar",
      loadCalendarDays: "productionCalendarStore/loadCalendarDays",
      loadEmployeeProductionCalendar:
        "employeeCalendarStore/loadEmployeeProductionCalendar",
    }),
    ...mapMutations({
      setMonthId: "planningsStore/setMonthId",
      setAvailability: "employeeCalendarStore/setAvailability",
      setChangeableMonthId: "employeeCalendarStore/setChangeableMonthId",
    }),
    async loadCalendar() {
      await this.loadEmployeeProductionCalendar();
    },
    setSearch(value) {
      if (typeof value !== "string") {
        return;
      }
      this.search = value.toLowerCase();
    },
    // fields[object { id: <string>, keys: <array> } | string] - массив, содержащий id свойств,
    // значения которых нам нужны, или объекты с полями 'id', 'keys'. Поле 'id' содержит id необходимого свойства,
    // поле keys - массив с ключами для формирования объекта value (подробнее о 'keys' в CommonFormRow.vue, метод getInputValue)
    getValues() {
      const fields = [
        { id: "telegram_contact" },
        { id: "phone" },
        { id: "tz", keys: ["timezone"] },
      ];
      const form = this.$refs["profile-form"];
      return form.getValues(fields);
    },
    async saveResult() {
      this.search = "";
      const params = this.getValues();
      await this.patchUser(params);
    },
    async restart() {
      await this.loadAvailabilityData();
    },
  },
};
</script>

<style lang="scss" scope>
.profile-block:not(:last-child) {
  margin-bottom: 80px;
}
</style>
